import React from 'react';
import '../../App.css';
import '../Styles/footer.css';

function footer() {
  return (
    <div className="footer" id="footer">
      <ul className="social-icon">
        <li className="social-icon__item"><a className="social-icon__link" href="https://www.facebook.com/profile.php?id=100063549355181&mibextid=LQQJ4d">
          <ion-icon name="logo-facebook"></ion-icon>
        </a></li>
        <li className="social-icon__item"><a className="social-icon__link" href="/">
          <ion-icon name="logo-twitter"></ion-icon>
        </a></li>
        <li className="social-icon__item"><a className="social-icon__link" href="/">
          <ion-icon name="logo-linkedin"></ion-icon>
        </a></li>
        <li className="social-icon__item"><a className="social-icon__link" href="/">
          <ion-icon name="logo-instagram"></ion-icon>
        </a></li>
      </ul>
      <ul className="menu">
        <li className="menu__item"><a className="menu__link" href="/">Home</a></li>
        <li className="menu__item"><a className="menu__link" href="/#about">About</a></li>
        <li className="menu__item"><a className="menu__link" href="/#services">Services</a></li>
        <li className="menu__item"><a className="menu__link" href="/#team">Team</a></li>
        <li className="menu__item"><a className="menu__link" href="/#contact">Contact</a></li>
      </ul>
      <p>&copy;Mercury Sales Corporation | All Rights Reserved</p>
    </div>
  );
}

export default footer;