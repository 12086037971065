import React, { useEffect} from 'react';
import '../../App.css';
import '../Styles/navbar.css';
import logo from '../Assets/Logo.png';

function Navbar() {

  useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      const navbar = document.getElementById("navbar");

      if (navbar) {
        if (document.body.scrollTop > 5 || document.documentElement.scrollTop > 5) {
          navbar.style.top = "0";
        } else {
          navbar.style.top = "-12vh";
        }
      }
    }
    document.getElementById('dropbtn').onclick = function () {
      var x = document.getElementById("myLinks");
      if (x.style.display === "block") {
        x.style.display = "none";
       
      } else {
        x.style.display = "block";
       
      }
    }
  }, []);

  return (
    <header className="navbar" id="navbar">
      <span><img src={logo} className="logo" alt="logo" /></span>
     
      <ul className="myLinks" >
        <li><a href="/#">Home</a></li>
        <li><a href="/#about">About</a></li>
        <li><a href="/#contact">Contacts</a></li>
      </ul>

      <div className="dropdown">
      <button Id="dropbtn"> 
      <i className="fa fa-bars"></i>
      </button>
    <div className="dropdown-content" Id="myLinks">
        <a href="/#">Home</a>
        <a href="/#about">About</a>
        <a href="/#contact">Contacts</a>
    </div>
  </div> 
    </header>
  );
}

export default Navbar;
