import React, { useEffect } from 'react';
import '../../App.css';
import '../Styles/product.css';
import product1 from '../Assets/product1.jpg';
import product2 from '../Assets/product2.jpg';
import product3 from '../Assets/product3.jpg';
import product4 from '../Assets/product4.jpg';
import product5 from '../Assets/product5.jpg';
import product6 from '../Assets/product6.jpg';
import product7 from '../Assets/product7.jpg';
import product8 from '../Assets/product8.jpg';

function Product() {
  useEffect(() => {
   
    document.getElementById('next').onclick = function () {
      let lists = document.querySelectorAll('.item');
      document.getElementById('slide').appendChild(lists[0]);
    }
    
    document.getElementById('prev').onclick = function () {
      let lists = document.querySelectorAll('.item');
      document.getElementById('slide').prepend(lists[lists.length - 1]);
    }
  }, []);

  return (
    <div className='product' id="product">
      <div className="proHead">PRODUCT</div>
      <div className="container">
        <div className="buttons" Id="left">
          <button Id="prev" className="prev"><i className="fa-solid fa-angle-left"></i></button>
        </div>
        <div className="slide" Id="slide">
          <div className="item" style={{backgroundImage: `url(${product1})` }}> <center>MS PIPES<br/>IS1239/1161</center></div>
          <div className="item" style={{backgroundImage: `url(${product2})` }}><center>GALVANIZED PIPES<br/> IS 1239</center></div>
          <div className="item" style={{backgroundImage: `url(${product3})` }}><center>SEAMLESS PIPES<br/> ASTM A106 GR-B</center></div>
          <div className="item" style={{backgroundImage: `url(${product4})` }}><center>HR/CR COILS<br/> IS2062/10748</center></div>
          <div className="item" style={{backgroundImage: `url(${product5})` }}><center>HR/CR SHEETS & PLATES<br/> IS2062</center></div>
          <div className="item" style={{backgroundImage: `url(${product6})` }}><center>MS/GI/SEAMLESS PIPE <br/> FITTINGS</center></div>
          <div className="item" style={{backgroundImage: `url(${product7})` }}><center>SHS/RHS PIPES<br/> IS4923</center></div>
          <div className="item" style={{backgroundImage: `url(${product8})` }}><center>VALVES </center></div>
        </div>
        
        <div className="buttons" Id="right">
          <button Id="next" className="next"><i className="fa-solid fa-angle-right"></i></button>
        </div>
    </div>
  </div>
);
}

export default Product;
