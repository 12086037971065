import React from 'react';
import '../../App.css';
import '../Styles/hero.css';
import image from '../Assets/hero-image.png';

function hero() {
  return (
    <div className="hero">
      <div className="content">
        <div></div>
        <span className="title">MERCURY<br/> SALES<br/> CORPORATION</span>
        <div className="text">Delivering Pipeline Solutions Since 1978</div>
        <div></div>
      </div>
      <img className="side_img" src={image} alt='bg' />
    </div>
  );
}

export default hero;