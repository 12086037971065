import React from 'react';
import '../../App.css';
import '../Styles/contact.css';

const data = {
    name: '',
    email: '',
    phone: '',
    query: '',
}

function contact() {
  return (
    <div className="contact" id="contact">
        <header className="contact_heading">
            <h1>Customer Query Form</h1>
        </header>
        <main>
            <form action="/submit-form" method="post">
                <div class="row">
                    <div class="col-25">
                        <label for="name">Name</label>
                    </div>
                    <div class="col-75">
                        <input type="text" id="name" name="Name" placeholder="Enter your name here" ref={node => (data.name = node)} />
                    </div>
                </div>
                <div class="row">
                    <div class="col-25">
                        <label for="email">Email</label>
                    </div>
                    <div class="col-75">
                        <input type="email" id="email" name="Email" placeholder="Enter your email here" ref={node => (data.email = node)} />
                    </div>
                </div>
                <div class="row">
                    <div class="col-25">
                        <label for="phone">Phone</label>
                    </div>
                    <div class="col-75">
                        <input type="tel" id="phone" name="Phone" placeholder="Enter your phone number here" ref={node => (data.phone = node)} />
                    </div>
                </div>
                <div class="row">
                    <div class="col-25">
                        <label for="query">Query</label>
                    </div>
                    <div class="col-75">
                        <textarea id="query" name="Query" placeholder="Enter your query" rows="4" ref={node => (data.query = node)} ></textarea>
                    </div>
                </div>
                <div class="row">
                    <button type="submit" onClick={submit}>Submit</button>
                </div>
            </form>
        </main>
    </div>
  );
}

function submit() {
    console.log(`Name: ${data.name.value}\nEmail: ${data.email.value}\nPhone: ${data.phone.value}\nQuery: ${data.query.value}\n`);
}

export default contact;

